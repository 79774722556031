import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./business/Navbar/Navbar";
import Logo from "./presentation/Commons/Logo/Logo";
import "./fontello/css/fontello.css";
import Home from "./presentation/Home/Home";
import Picks from "./presentation/Picks/Picks";
import SportsMachine from "./presentation/SportsMachine/SportsMachine";
import AuthButton from "./business/Button/AuthButton";
import { useAuth } from "./database/context/authContext";
import Stats from "./presentation/Stats/Stats";
import InitialBankForm from "./business/HandleUser/BankControl/InitialBank";
import Profile from "./business/HandleUser/Profile";
import ChallengeViewer from "./business/Challenges/ChallengeViewer";
import RaffleList from "./business/Raflles/RaffleList";

function AppContent() {
  const [showingComponent, setShowingComponent] = useState("home");
  const { user, isFirstTime, setIsFirstTime, initialBank } = useAuth();

  const handleBankSet = () => {
    setIsFirstTime(false);
  };

  useEffect(() => {
    if (initialBank !== null) {
      console.log("User's Initial Bank:", initialBank);
    }
  }, [initialBank]);

  return (
    <div className="app">
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <AuthButton />
      </div>
      <Logo
        src={require("../src/images/logo.png")}
        alt="Logo de la Aplicación"
      />
      {user && isFirstTime ? (
        <InitialBankForm onBankSet={handleBankSet} />
      ) : (
        <>
          {showingComponent === "home" && <Home />}
          {showingComponent === "picks" && <Picks />}
          {showingComponent === "cb" && <Stats />}
          {showingComponent === "pf" && <Profile />}
          {showingComponent === "rt" && <ChallengeViewer />}
          {showingComponent === "dn" && <RaffleList />}
          <Navbar setShowingComponent={setShowingComponent} />
        </>
      )}
    </div>
  );
}

export default AppContent;
