import React, { useState } from "react";
import "./Stats.css"; // Asegúrate de tener tus estilos CSS
import AllTimeRecord from "../../business/Stats/AllTimeRecord";
import CurrentMonth from "../../business/Stats/CurrentMonth";

const Stats = () => {
  const [activeSection, setActiveSection] = useState("currentMonth");

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  return (
    <div className="stats-container">
      <div className="section-buttons">
        <button
          className={activeSection === "currentMonth" ? "active" : ""}
          onClick={() => handleSectionChange("currentMonth")}
        >
          Mes actual
        </button>
        <button
          className={activeSection === "panenkaHistory" ? "active" : ""}
          onClick={() => handleSectionChange("panenkaHistory")}
        >
          Historial Panenka
        </button>
      </div>
      <div className="stats-container">
        {activeSection === "currentMonth" ? (
          <CurrentMonth />
        ) : (
          <AllTimeRecord />
        )}
      </div>
    </div>
  );
};

export default Stats;
