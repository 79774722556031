import React, { useState } from "react";
import { useAuth } from "../../../database/context/authContext";
import { db } from "../../../database/firebase";
import { doc, setDoc } from "firebase/firestore";
import "./InitialBank.css"; // Importa el archivo CSS

const InitialBankForm = ({ onBankSet }) => {
  const [bank, setBank] = useState("");
  const { user } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      try {
        const userDoc = doc(db, "users", user.uid);
        await setDoc(userDoc, { initialBank: bank }, { merge: true });
        onBankSet();
      } catch (error) {
        console.error("Error setting initial bank:", error);
      }
    }
  };

  return (
    <div className="initial-bank-form-container">
      <h2>Dinero destinado para el mes</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          value={bank}
          onChange={(e) => setBank(e.target.value)}
          placeholder="Ingresa tu bank inicial"
          required
        />
        <button type="submit">Ingresar</button>
      </form>
    </div>
  );
};

export default InitialBankForm;
