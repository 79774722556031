import React, { useState, useEffect } from "react";
import { db } from "../../database/firebase";
import { collection, query, getDocs } from "firebase/firestore";

const sportIcons = {
  Futbol: "icon-soccer",
  NBA: "icon-basketball",
  MLB: "icon-baseball",
  NHL: "icon-school",
  NFL: "icon-football",
};

const picksCollections = {
  Futbol: ["futbol-picks", "futbol-parlays-picks"],
  NBA: ["nba-picks", "nba-parlays-picks"],
  MLB: ["mlb-picks", "mlb-parlays-picks"],
  NHL: ["nhl-picks", "nhl-parlays-picks"],
  NFL: ["nfl-picks", "nfl-parlays-picks"],
};

const AdvancedPicks = () => {
  const [selectedSport, setSelectedSport] = useState("Futbol");
  const [picksData, setPicksData] = useState({});
  const [straightPicksEffectiveness, setStraightPicksEffectiveness] =
    useState(0);
  const [parlayPicksEffectiveness, setParlayPicksEffectiveness] = useState(0);
  const [leaguePicksData, setLeaguePicksData] = useState({});
  const [noPicksMessage, setNoPicksMessage] = useState(null);
  const [showEffectiveness, setShowEffectiveness] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const picksRef = collection(db, picksCollections[selectedSport][0]);
      const picksSnapshot = await getDocs(picksRef);
      const newPicksData = {};
      picksSnapshot.forEach((doc) => {
        const pickData = doc.data();
        const pickType = pickData.pickType;
        if (newPicksData[pickType]) {
          newPicksData[pickType].push(pickData);
        } else {
          newPicksData[pickType] = [pickData];
        }
      });

      if (Object.keys(newPicksData).length === 0) {
        setNoPicksMessage(`No hay picks disponibles para ${selectedSport}.`);
        setShowEffectiveness(false);
      } else {
        setNoPicksMessage(null);
        setShowEffectiveness(true);
      }

      setPicksData(newPicksData);
    };

    fetchData();
  }, [selectedSport]);

  useEffect(() => {
    const calculateEffectiveness = () => {
      let straightPicksTotal = 0;
      let straightPicksWon = 0;
      let parlayPicksTotal = 0;
      let parlayPicksWon = 0;

      const straightPicksRef = collection(
        db,
        picksCollections[selectedSport][0]
      );
      const parlayPicksRef = collection(db, picksCollections[selectedSport][1]);

      getDocs(straightPicksRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          straightPicksTotal++;
          if (doc.data().result === "ganado") {
            straightPicksWon++;
          }
        });
        setStraightPicksEffectiveness(
          straightPicksTotal === 0
            ? 0
            : (straightPicksWon / straightPicksTotal) * 100
        );
      });

      getDocs(parlayPicksRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          parlayPicksTotal++;
          if (doc.data().result === "ganado") {
            parlayPicksWon++;
          }
        });
        setParlayPicksEffectiveness(
          parlayPicksTotal === 0 ? 0 : (parlayPicksWon / parlayPicksTotal) * 100
        );
      });
    };

    calculateEffectiveness();
  }, [selectedSport]);

  useEffect(() => {
    if (selectedSport === "Futbol") {
      const fetchLeaguePicks = async () => {
        const picksRef = collection(db, picksCollections[selectedSport][0]);
        const picksSnapshot = await getDocs(picksRef);
        const leaguePicks = {};
        picksSnapshot.forEach((doc) => {
          const pickData = doc.data();
          const league = pickData.league;
          if (leaguePicks[league]) {
            leaguePicks[league].push(pickData);
          } else {
            leaguePicks[league] = [pickData];
          }
        });
        setLeaguePicksData(leaguePicks);
      };

      fetchLeaguePicks();
    }
  }, [selectedSport]);

  const handleSportChange = (sport) => {
    setSelectedSport(sport);
  };

  return (
    <div className="advanced-container">
      <div className="picks-table-container">
        <h2>Picks de {selectedSport}</h2>
        <div className="sport-filter">
          {Object.keys(sportIcons).map((sport) => (
            <button
              key={sport}
              className={`sport-toggle ${
                selectedSport === sport ? "active" : ""
              }`}
              onClick={() => handleSportChange(sport)}
            >
              <span className={`icon ${sportIcons[sport]}`}></span>
              {sport}
            </button>
          ))}
        </div>
      </div>
      {showEffectiveness && (
        <div className="stats-adv">
          <h3>Efectividad de jugadas</h3>
          <div>
            <p>
              Efectividad de jugadas en parlay:{" "}
              {parlayPicksEffectiveness.toFixed(1)}%
            </p>
            <p>
              Efectividad de jugadas en derecha:{" "}
              {straightPicksEffectiveness.toFixed(1)}%
            </p>
          </div>
        </div>
      )}
      {noPicksMessage ? (
        <p>{noPicksMessage}</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Tipo derecha</th>
              <th>Efectividad</th>
              <th>Total de jugadas</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(picksData)
              .map(([pickType, picks]) => {
                const totalPicks = picks.length;
                const wonPicks = picks.filter(
                  (pick) => pick.result === "ganado"
                ).length;
                const effectiveness =
                  totalPicks === 0 ? 0 : (wonPicks / totalPicks) * 100;
                return { pickType, effectiveness, totalPicks };
              })
              .sort((a, b) => b.effectiveness - a.effectiveness)
              .map(({ pickType, effectiveness, totalPicks }) => (
                <tr key={pickType}>
                  <td>{pickType}</td>
                  <td>{effectiveness.toFixed(1)}%</td>
                  <td>{totalPicks}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {selectedSport === "Futbol" && (
        <div className="league-stats">
          <h3>Efectividad por liga</h3>
          {noPicksMessage ? (
            <p>{noPicksMessage}</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Liga</th>
                  <th>Efectividad</th>
                  <th>Total de jugadas</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(leaguePicksData)
                  .map(([league, picks]) => {
                    const totalPicks = picks.length;
                    const wonPicks = picks.filter(
                      (pick) => pick.result === "ganado"
                    ).length;
                    const effectiveness =
                      totalPicks === 0 ? 0 : (wonPicks / totalPicks) * 100;
                    return { league, effectiveness, totalPicks };
                  })
                  .sort((a, b) => b.effectiveness - a.effectiveness)
                  .map(({ league, effectiveness, totalPicks }) => (
                    <tr key={league}>
                      <td>{league}</td>
                      <td>{effectiveness.toFixed(1)}%</td>
                      <td>{totalPicks}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default AdvancedPicks;
