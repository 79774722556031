import React, { useEffect, useState } from "react";
import "./Navbar.css";

const Navbar = ({ setShowingComponent }) => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);

  useEffect(() => {
    let timeoutId;

    const handleUserActivity = () => {
      setIsNavbarVisible(true); // Mostrar la barra de navegación
      clearTimeout(timeoutId); // Reiniciar el temporizador
      timeoutId = setTimeout(() => {
        setIsNavbarVisible(false); // Ocultar la barra de navegación después de 3.5 segundos de inactividad
      }, 3500);
    };

    // Comenzar el temporizador cuando se monta el componente
    timeoutId = setTimeout(() => {
      setIsNavbarVisible(false);
    }, 3500);

    // Escuchar eventos de ratón y toque para reiniciar el temporizador
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("touchstart", handleUserActivity);

    // Limpiar el temporizador al desmontar el componente
    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("touchstart", handleUserActivity);
    };
  }, []);

  return (
    <nav className={`bottom-navbar ${isNavbarVisible ? "visible" : "hidden"}`}>
      <div className="navbar-content">
        <div
          className="navbar-button"
          onClick={() => setShowingComponent("home")}
        >
          <i className="icon-home" />
          <p>Inicio</p>
        </div>
        <div
          className="navbar-button"
          onClick={() => setShowingComponent("picks")}
        >
          <i className="icon-target" />
          <p>Picks</p>
        </div>
        <div
          className="navbar-button"
          onClick={() => setShowingComponent("cb")}
        >
          <i className="icon-chart" />
          <p>Estadisticas</p>
        </div>
        <div
          className="navbar-button"
          onClick={() => setShowingComponent("rt")}
        >
          <i className="icon-rocket" />
          <p>Retos</p>
        </div>
        <div
          className="navbar-button"
          onClick={() => setShowingComponent("dn")}
        >
          <i className="icon-group" />
          <p>Premios</p>
        </div>
      </div>
      <div className="logo">
        <img src={require("../../images/luccino.png")} />
      </div>
    </nav>
  );
};

export default Navbar;
