import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./DescriptionComponent.css";

const DescriptionComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  return (
    <div className="description-container">
      <Slider {...settings}>
        <div className="section">
          <h2 className="section-title">
            ¡Bienvenido a Panenka Sports Analysis!
          </h2>
          <p className="paragraph">
            Tu destino para apuestas deportivas de calidad y análisis
            estadístico.
          </p>
        </div>
        <div className="section">
          <h2 className="section-title">Apuestas Deportivas</h2>
          <p className="paragraph">
            Explora una amplia gama de opciones de apuestas deportivas, desde
            fútbol, NBA, NFL, MLB o hasta la emocionante NHL!
          </p>
        </div>
        <div className="section">
          <h2 className="section-title">Estadísticas Detalladas</h2>
          <p className="paragraph">
            Descubre nuestro detallado análisis de apuestas ganadas, perdidas y
            más. Analiza el rendimiento por deporte y tipo de apuesta.
          </p>
        </div>
        <div className="section">
          <h2 className="section-title">Premios Panenka</h2>
          <p className="paragraph">
            Participa en nuestras emocionantes dinámicas de premios mensuales.
            Algunos premios son exclusivos para miembros premium, mientras que
            otros están abiertos para todos los participantes.
          </p>
        </div>
        <div className="section">
          <h2 className="section-title">Retos</h2>
          <p className="paragraph">
            Únete a nuestros desafíos de escalera, donde transformarás pequeñas
            cantidades en grandes victorias en retos emocionantes.
          </p>
        </div>
      </Slider>
    </div>
  );
};

export default DescriptionComponent;
