import React, { useState, useEffect } from "react";
import { db } from "../../database/firebase";
import { collection, query, getDocs } from "firebase/firestore";
import { useAuth } from "../../database/context/authContext";
import "./PicksTable.css";

const sportIcons = {
  Futbol: "icon-soccer",
  NBA: "icon-basketball",
  MLB: "icon-baseball",
  NHL: "icon-school",
  NFL: "icon-football",
};

const PicksTable = ({ selectedGroup }) => {
  const [picksData, setPicksData] = useState([]);
  const [podData, setPodData] = useState([]);
  const [selectedSport, setSelectedSport] = useState("Futbol");
  const [viewMode, setViewMode] = useState("sport"); // "sport" or "pod"
  const [noPicksMessage, setNoPicksMessage] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    const fetchPicksData = async () => {
      try {
        const picksCollections = {
          Futbol: ["futbol-picks", "futbol-parlays-picks"],
          NBA: ["nba-picks", "nba-parlays-picks"],
          MLB: ["mlb-picks", "mlb-parlays-picks"],
          NHL: ["nhl-picks", "nhl-parlays-picks"],
          NFL: ["nfl-picks", "nfl-parlays-picks"],
        };

        let allPicks = [];
        let allPodPicks = [];
        let winCount = 0;
        let totalCount = 0;
        let podWinCount = 0;
        let podTotalCount = 0;

        for (const sport in picksCollections) {
          for (const pickCollection of picksCollections[sport]) {
            const picksQuery = query(collection(db, pickCollection));
            const picksSnapshot = await getDocs(picksQuery);

            picksSnapshot.forEach((doc) => {
              const pickData = doc.data();
              // Check if the pick is premium and the user is not logged in
              if (pickData.premium && !user) {
                return;
              }

              // Check if the pick is premium and does not have a result, skip if user is not logged in
              if (pickData.premium && !user && !pickData.result) {
                return;
              }

              const formattedData = {
                id: doc.id,
                game: pickData.selections
                  ? `Parlay de ${pickData.selections.length} juegos`
                  : `${pickData.homeTeam} vs ${pickData.awayTeam}`,
                date: pickData.date.seconds,
                pick: pickData.selections
                  ? pickData.selections
                      .map((selection) => selection.pick)
                      .join(" + ")
                  : pickData.pick,
                odds: pickData.odds,
                stake: pickData.stake,
                result: pickData.result,
                pod: pickData.POD,
                premium: pickData.premium,
              };

              // Registro de resultados
              if (pickData.result === "ganado") {
                winCount++;
              }
              if (pickData.result !== null && pickData.result !== "push") {
                totalCount++;
              }
              if (pickData.POD) {
                podTotalCount++;
                allPodPicks.push(formattedData);
                if (pickData.result === "ganado") {
                  podWinCount++;
                }
              } else {
                if (sport === selectedSport) {
                  allPicks.push(formattedData);
                }
              }
            });
          }
        }

        allPicks.sort((a, b) => b.date - a.date);
        allPodPicks.sort((a, b) => b.date - a.date);
        setPicksData(allPicks);
        setPodData(allPodPicks);

        if (viewMode === "sport" && allPicks.length === 0) {
          setNoPicksMessage(`No hay picks para ${selectedSport} este mes.`);
        } else if (viewMode === "pod" && allPodPicks.length === 0) {
          setNoPicksMessage(`No hay picks POD este mes.`);
        } else {
          setNoPicksMessage("");
        }
      } catch (error) {
        console.error("Error fetching picks data:", error);
      }
    };

    fetchPicksData();
  }, [selectedSport, user, selectedGroup, viewMode]);

  useEffect(() => {
    setSelectedSport("Futbol");
  }, [selectedGroup]);

  const handleSportChange = (sport) => {
    setSelectedSport(sport);
  };

  const calculateWinLossPercentage = (result, adjustedStake, odds) => {
    adjustedStake = Number(adjustedStake);
    odds = Number(odds.split("/")[0]);

    if (result === "ganado") {
      return "+" + (adjustedStake * (odds - 1)).toFixed(2);
    } else if (result === "perdedor") {
      return (-adjustedStake).toFixed(2);
    } else {
      return "0.00";
    }
  };

  return (
    <div className="picks-table-container">
      <h2>Picks de {viewMode === "sport" ? selectedSport : "POD"}</h2>
      <div className="sport-filter">
        <button
          className={`sport-toggle ${viewMode === "pod" ? "active" : ""}`}
          onClick={() => setViewMode(viewMode === "sport" ? "pod" : "sport")}
        >
          {viewMode === "sport" ? "Ver PODs" : "Ver por Deporte"}
        </button>{" "}
        <br />
        {viewMode === "sport" &&
          Object.keys(sportIcons).map((sport) => (
            <button
              key={sport}
              className={`sport-toggle ${
                selectedSport === sport ? "active" : ""
              }`}
              onClick={() => handleSportChange(sport)}
            >
              <span className={`icon ${sportIcons[sport]}`}></span>
              {sport}
            </button>
          ))}
        {noPicksMessage && <p>{noPicksMessage}</p>}
        <div className="legend">
          <p>Nota: Las jugadas exclusivas premium presentan borde dorado</p>
        </div>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Juego</th>
              <th>Pick</th>
              <th>Odds</th>
              <th>Stake</th>
              <th>Resultado</th>
              <th>% G/P</th>
            </tr>
          </thead>
          <tbody>
            {(viewMode === "sport" ? picksData : podData).map((pick) => (
              <tr
                key={pick.id}
                className={`${pick.pod ? "pod-row" : ""} ${
                  pick.premium ? "premium" : ""
                }`}
              >
                <td>{new Date(pick.date * 1000).toLocaleDateString()}</td>
                <td>{pick.game}</td>
                <td>{pick.pick}</td>
                <td>{pick.odds}</td>
                <td>{pick.stake}</td>
                <td
                  className={
                    pick.result === "ganado"
                      ? "green-background"
                      : pick.result === "perdedor"
                      ? "red-background"
                      : ""
                  }
                >
                  {pick.result}
                </td>
                <td>
                  {calculateWinLossPercentage(
                    pick.result,
                    pick.adjustedStake, // Usamos el stake ajustado
                    pick.odds
                  )}
                  %
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PicksTable;
