import React, { useState, useEffect } from "react";
import { db } from "../../database/firebase";
import { collection, query, getDocs } from "firebase/firestore";
import "./CurrentMonth.css";
import PicksTable from "./PicksTable";
import AdvancedStats from "./AdvancedStats";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const CurrentMonth = () => {
  const [currentMonthStats, setCurrentMonthStats] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState("free");
  const [showPicksList, setShowPicksList] = useState(true);
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const fetchCurrentMonthStats = async () => {
      try {
        const collections = [
          {
            name: "Futbol",
            picks: ["futbol-picks", "futbol-parlays-picks"],
            bankPercentage: 0.3,
          },
          {
            name: "MLB",
            picks: ["mlb-picks", "mlb-parlays-picks"],
            bankPercentage: 0.3,
          },
          {
            name: "NBA",
            picks: ["nba-picks", "nba-parlays-picks"],
            bankPercentage: 0,
          },
          {
            name: "NHL",
            picks: ["nhl-picks", "nhl-parlays-picks"],
            bankPercentage: 0,
          },
          {
            name: "NFL",
            picks: ["nfl-picks", "nfl-parlays-picks"],
            bankPercentage: 0,
          },
        ];

        let totalStake = 0;
        let totalProfit = 0;
        let podWins = 0;
        let podLosses = 0;
        let sportsEffectiveness = {};
        let dailyResults = {};

        const today = new Date();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();

        const bankAllocation = {
          POD: 0.4, // 40% of bank
        };

        for (const sport of collections) {
          sportsEffectiveness[sport.name] = {
            totalPicks: 0,
            totalWins: 0,
            podWins: 0,
            podLosses: 0,
          };

          for (const pickCollection of sport.picks) {
            const picksQuery = query(collection(db, pickCollection));
            const picksSnapshot = await getDocs(picksQuery);

            picksSnapshot.forEach((doc) => {
              const pickData = doc.data();
              const stake = parseFloat(pickData.stake);
              const odds = parseFloat(pickData.odds.split("/")[0]);

              if (!isNaN(stake) && !isNaN(odds)) {
                const result = pickData.result;
                const isPOD = pickData.POD || false;
                const isPremium = pickData.premium || false;

                if (
                  result !== "push" &&
                  result !== "" &&
                  (selectedGroup === "premium" || !isPremium)
                ) {
                  // Ajustar stake basado en la asignación del banco
                  let adjustedStake = stake;
                  if (isPOD) {
                    adjustedStake *= bankAllocation.POD;
                  } else {
                    adjustedStake *= sport.bankPercentage;
                  }

                  // Aseguramos que estamos acumulando los stakes ajustados
                  totalStake += adjustedStake;
                  sportsEffectiveness[sport.name].totalPicks++;

                  const date = new Date(pickData.date.seconds * 1000);

                  // Verificar que la fecha sea del mes actual
                  if (
                    date.getMonth() === currentMonth &&
                    date.getFullYear() === currentYear
                  ) {
                    const dateKey = date.toISOString().split("T")[0];

                    if (!dailyResults[dateKey]) {
                      dailyResults[dateKey] = 0;
                    }

                    if (isPOD) {
                      if (result === "ganado") {
                        podWins++;
                        sportsEffectiveness[sport.name].podWins++;
                      } else if (result === "perdedor") {
                        podLosses++;
                        sportsEffectiveness[sport.name].podLosses++;
                      }
                    }

                    if (result === "ganado") {
                      totalProfit += adjustedStake * (odds - 1);
                      dailyResults[dateKey] += adjustedStake * (odds - 1);
                      sportsEffectiveness[sport.name].totalWins++;
                    } else if (result === "perdedor") {
                      totalProfit -= adjustedStake;
                      dailyResults[dateKey] -= adjustedStake;
                    }
                  }
                }
              }
            });
          }
        }

        // Ordenar fechas
        const sortedDates = Object.keys(dailyResults).sort(
          (a, b) => new Date(a) - new Date(b)
        );

        let cumulativeResults = [];
        let cumulativeTotal = 0;
        for (const date of sortedDates) {
          cumulativeTotal += dailyResults[date];
          cumulativeResults.push({
            date,
            value: cumulativeTotal,
          });
        }

        setChartData({
          labels: cumulativeResults.map((result) => result.date),
          datasets: [
            {
              label: "Ganancia/Pérdida Acumulada",
              data: cumulativeResults.map((result) => result.value),
              fill: false,
              backgroundColor: "rgba(0,0,0,1)",
              borderColor: "rgba(75,192,192,1)",
            },
          ],
        });

        // Calcular porcentaje de balance
        const balancePercentage = ((totalProfit / totalStake) * 100).toFixed(2);

        setCurrentMonthStats({
          totalProfit,
          balancePercentage,
          podWins,
          podLosses,
          sportsEffectiveness,
        });
      } catch (error) {
        console.error("Error fetching current month stats:", error);
      }
    };

    fetchCurrentMonthStats();
  }, [selectedGroup]);

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleViewChange = () => {
    setShowPicksList(!showPicksList);
  };

  if (!currentMonthStats) {
    return <div>Cargando...</div>;
  }

  const getColorClass = (value) => {
    if (value >= 0) {
      return "positive";
    } else {
      return "negative";
    }
  };

  return (
    <div className="current-month-container">
      <h2>Estadísticas del Mes Actual</h2>
      <div className="group-filter">
        <h3>Grupo:</h3>
        <div className="check-buttons">
          <label>
            <input
              type="radio"
              name="group"
              value="free"
              checked={selectedGroup === "free"}
              onChange={handleGroupChange}
            />
            Free
          </label>
          <label>
            <input
              type="radio"
              name="group"
              value="premium"
              checked={selectedGroup === "premium"}
              onChange={handleGroupChange}
            />
            Premium
          </label>
        </div>
      </div>
      <div className="profit-container">
        <div
          className={`profit-card ${getColorClass(
            currentMonthStats.totalProfit
          )}`}
        >
          <p>Balance actual del mes:</p>
          <h3>
            {currentMonthStats.totalProfit >= 0 ? "+" : "-"}
            {Math.abs(currentMonthStats.totalProfit).toFixed(2)} %
          </h3>
          <div className="pod-record">
            <p>
              Record POD: {currentMonthStats.podWins} -{" "}
              {currentMonthStats.podLosses}
            </p>
          </div>
          <div className="selected-group">Grupo {selectedGroup}</div>
        </div>
      </div>
      <div className="view-toggle">
        <button onClick={handleViewChange} className="premium-btn">
          {showPicksList ? "Ver stats avanzadas" : "Ver lista picks"}
        </button>
      </div>
      <div className="picks-chart-container">
        <h2>Ganancia/Pérdida Acumulada por Día</h2>
        <Line data={chartData} />
      </div>
      {showPicksList ? (
        <div>
          <PicksTable selectedGroup={selectedGroup} />
        </div>
      ) : (
        <AdvancedStats selectedGroup={selectedGroup} />
      )}
    </div>
  );
};

export default CurrentMonth;
