import React, { useEffect, useState } from "react";
import { useAuth } from "../../database/context/authContext";
import "./Cards.css";

const PickCard = ({ pick }) => {
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [stakeAmount, setStakeAmount] = useState(0);

  const { initialBank } = useAuth();

  useEffect(() => {
    calculateStake();
  }, [initialBank, pick]);

  const toggleAnalysis = () => {
    setShowAnalysis(!showAnalysis);
  };

  const renderStakeIcons = () => {
    const stakePercentage = Math.round(pick.stake / 5);
    const icons = [];

    for (let i = 0; i < stakePercentage; i++) {
      icons.push(<i key={i} className="icon-star" />);
    }

    return icons;
  };

  const getResultLabel = () => {
    if (pick.result === "ganado") {
      return <div className="result-label ganador">Ganador</div>;
    } else if (pick.result === "perdedor") {
      return <div className="result-label perdedor">Perdedor</div>;
    } else if (pick.result === "push") {
      return <div className="result-label push">Push</div>;
    } else {
      return <div className="result-label por-jugarse">Por Jugarse</div>;
    }
  };

  const renderAnalysis = () => {
    if (pick.analysis && pick.analysis.length > 0) {
      return pick.analysis.map((point, index) => (
        <div key={index} className="analysis-point">
          {pick.sport === "futbol" && <i className="icon-soccer" />}
          {pick.sport === "basket" && <i className="icon-basketball" />}
          {pick.sport === "nfl" && <i className="icon-football" />}
          {pick.sport === "mlb" && <i className="icon-baseball" />}
          {pick.sport === "nhl" && <i className="icon-school" />}
          <span>{point}</span>
        </div>
      ));
    } else {
      return <div>No hay análisis disponible.</div>;
    }
  };

  const calculateStake = () => {
    let bankAllocationPercentage = 0;

    // Determinar el porcentaje de asignación del bank
    if (pick.POD) {
      bankAllocationPercentage = 0.4; // 40% para POD
    } else {
      switch (pick.sport) {
        case "futbol":
          bankAllocationPercentage = 0.3; // 30% para fútbol
          break;
        case "mlb":
          bankAllocationPercentage = 0.3; // 30% para MLB
          break;
        // Añadir otros deportes si tienen asignaciones específicas
        default:
          bankAllocationPercentage = 0.1; // 10% como valor predeterminado
      }
    }

    // Calcular el stake basado en el porcentaje del bank asignado
    let calculatedStake = 0;

    if (pick.POD) {
      calculatedStake =
        (initialBank * bankAllocationPercentage * pick.stake) / 100;
    } else {
      calculatedStake =
        (initialBank * bankAllocationPercentage * pick.stake) / 100;
    }

    setStakeAmount(calculatedStake);
  };

  return (
    <div
      className={`pick-card ${pick.premium ? "premium" : ""} ${
        pick.POD ? "pod" : ""
      }`}
      style={{ backgroundImage: `url(${pick.imagen})` }}
    >
      <div className="card-overlay"></div>
      {pick.premium && <div className="premium-label">Pick premium</div>}
      {pick.POD && <div className="pod-label">Play Of the Day</div>}
      <div className="card-content">
        <div className="league">
          <h5>{pick.league}</h5>
        </div>
        <div className="teams-row">
          <div className="team-logo-container">
            <img
              src={pick.homeTeamEscudo}
              alt={`${pick.homeTeam} Logo`}
              className="team-logo"
            />
          </div>
          <div className="team-logo-container">
            <img
              src={pick.awayTeamEscudo}
              alt={`${pick.awayTeam} Logo`}
              className="team-logo"
            />
          </div>
        </div>
        <div className="names-row">
          <h3 className="teams">
            {pick.homeTeam} vs {pick.awayTeam}
          </h3>
        </div>
        <p className="day">{pick.day}</p>
        <div className="pick">{pick.pick}</div>
        {getResultLabel()}
        <div className="odds">{pick.odds}</div>
        <div className="stake-icons">{renderStakeIcons()}</div>
        <p className="day">
          Stake: {pick.stake}% <br />
        </p>

        {stakeAmount !== 0 && (
          <div className="stake-amount">
            Cantidad a apostar de acuerdo a tu bank: <br />$
            {stakeAmount.toFixed(2)}
          </div>
        )}

        <button className="analysis-button" onClick={toggleAnalysis}>
          {showAnalysis ? "Ocultar Análisis" : "Ver Análisis"}
        </button>
        {showAnalysis && (
          <div className="analysis-container">{renderAnalysis()}</div>
        )}
      </div>
    </div>
  );
};

export default PickCard;
