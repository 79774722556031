// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB2Ns-cs5eeL3LAYUXaMoyMWje-GmCeHpw",
  authDomain: "panenka-sa.firebaseapp.com",
  projectId: "panenka-sa",
  storageBucket: "panenka-sa.appspot.com",
  messagingSenderId: "408929794460",
  appId: "1:408929794460:web:0b52c280b586ca4623f059",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

/**
 * Upload a file to firebase storage
 * @param {File} file the file to upload
 * @param {nameFile} nameFile the name to save the file
 * @returns {Promise<string>} url of the upload file
 */
export async function uploadFile(file, nameFile) {
  const storageRef = ref(storage, nameFile);
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return url;
}
