import React, { useState, useEffect } from "react";
import { useAuth } from "../../database/context/authContext";
import "./ParlayCard.css";

const ParlayCard = ({ parlay }) => {
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [stakeAmount, setStakeAmount] = useState(0);
  const { initialBank } = useAuth();

  useEffect(() => {
    if (initialBank !== null) {
      calculateStake();
    }
  }, [initialBank]);

  const toggleAnalysis = () => {
    setShowAnalysis(!showAnalysis);
  };

  const renderSelections = () => {
    return parlay.selections.map((selection, index) => (
      <div key={index} className="selection">
        <p className="game">{selection.game}</p>
        <br />
        <p className="pick">Pick: {selection.pick}</p>
      </div>
    ));
  };

  const renderGlobalAnalysis = () => {
    if (parlay.selections && parlay.selections.length > 0) {
      return parlay.selections.map((selection, index) => (
        <div key={index} className="analysis-point">
          {selection.sport === "futbol" && <i className="icon-soccer" />}
          {selection.sport === "basket" && <i className="icon-basketball" />}
          {selection.sport === "nfl" && <i className="icon-football" />}
          {selection.sport === "mlb" && <i className="icon-baseball" />}
          {selection.sport === "nhl" && <i className="icon-school" />}
          <span>{selection.analysis}</span>
        </div>
      ));
    } else {
      return <div>No hay análisis disponible.</div>;
    }
  };

  const getResultLabel = () => {
    if (parlay.result === "ganado") {
      return <div className="result-label ganador">Ganador</div>;
    } else if (parlay.result === "perdedor") {
      return <div className="result-label perdedor">Perdedor</div>;
    } else if (parlay.result === "push") {
      return <div className="result-label push">Push</div>;
    } else {
      return <div className="result-label por-jugarse">Por Jugarse</div>;
    }
  };

  const renderStakeIcons = () => {
    const stakePercentage = Math.round(parlay.stake / 5);
    const icons = [];

    for (let i = 0; i < stakePercentage; i++) {
      icons.push(<i key={i} className="icon-star" />);
    }

    return icons;
  };

  const calculateStake = () => {
    let bankAllocationPercentage = 0;

    // Determinar el porcentaje de asignación del bank
    if (parlay.POD) {
      bankAllocationPercentage = 0.4; // 40% para POD
    } else {
      switch (parlay.sport) {
        case "futbol":
          bankAllocationPercentage = 0.3; // 30% para fútbol
          break;
        case "mlb":
          bankAllocationPercentage = 0.3; // 30% para MLB
          break;
        // Añadir otros deportes si tienen asignaciones específicas
        default:
          bankAllocationPercentage = 0.1; // 10% como valor predeterminado
      }
    }

    // Calcular el stake basado en el porcentaje del bank asignado
    let calculatedStake = 0;

    if (parlay.POD) {
      calculatedStake =
        (initialBank * bankAllocationPercentage * parlay.stake) / 100;
    } else {
      calculatedStake =
        (initialBank * bankAllocationPercentage * parlay.stake) / 100;
    }

    setStakeAmount(calculatedStake);
  };

  return (
    <div
      className={`pick-card ${parlay.premium ? "premium" : ""} ${
        parlay.POD ? "pod" : ""
      }`}
      style={{ backgroundImage: `url(${parlay.imagen})` }}
    >
      <div className="card-content">
        {parlay.premium && <div className="premium-label">Pick premium</div>}
        {parlay.POD && <div className="pod-label">Play Of the Day</div>}
        <h4>Jugada parlay</h4>
        <p className="day">{parlay.day}</p>
        <div className="selections-container">{renderSelections()}</div>
        {getResultLabel()}
        <div className="odds">{parlay.odds}</div>
        <div className="stake-icons">{renderStakeIcons()}</div>
        <p className="day">
          Stake: {parlay.stake}% <br />
        </p>
        {stakeAmount !== 0 && (
          <div className="stake-amount">
            Cantidad sugerida a apostar de acuerdo a tu bank: ${stakeAmount}
          </div>
        )}
        <button className="analysis-button" onClick={toggleAnalysis}>
          {showAnalysis ? "Ocultar Análisis" : "Ver Análisis"}
        </button>
        {showAnalysis && (
          <div className="analysis-container">{renderGlobalAnalysis()}</div>
        )}
      </div>
    </div>
  );
};

export default ParlayCard;
