import React, { useState, useEffect } from "react";
import { db } from "../../database/firebase";
import { collection, getDocs } from "firebase/firestore";
import { useAuth } from "../../database/context/authContext";
import "./ChallengeViewer.css";

const ChallengeViewer = () => {
  const [challenges, setChallenges] = useState([]);
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const { user } = useAuth();

  const fetchChallenges = async () => {
    const challengeCollection = collection(db, "challenges");
    const challengeSnapshot = await getDocs(challengeCollection);
    const challengeList = challengeSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setChallenges(challengeList);
  };

  useEffect(() => {
    fetchChallenges();
  }, []);

  const getLatestStepDate = (steps) => {
    if (steps.length === 0) return null;
    return steps.reduce((latest, step) => {
      return new Date(step.date) > new Date(latest.date) ? step : latest;
    }).date;
  };

  const sortedChallenges = challenges.sort((a, b) => {
    const dateA = getLatestStepDate(a.steps);
    const dateB = getLatestStepDate(b.steps);
    if (!dateA) return 1;
    if (!dateB) return -1;
    return new Date(dateB) - new Date(dateA);
  });

  return (
    <div className="challenge-viewer">
      <h2>Retos del mes</h2>
      <ul className="challenge-list">
        {sortedChallenges.map((challenge) => {
          const canViewChallenge =
            !challenge.isPremium || challenge.status !== "En progreso" || user;

          return (
            <li
              key={challenge.id}
              className={`challenge-item ${
                challenge.isPremium && !canViewChallenge ? "premium-pick" : ""
              }`}
            >
              <div className="card-content">
                <div className="premium-and-title">
                  {challenge.isPremium && (
                    <p className="premium-label">Premium</p>
                  )}
                  <p>
                    <strong>{challenge.name}</strong>
                  </p>
                </div>
                <p>Cantidad inicial: ${challenge.initialAmount}</p>
                <p>Cantidad actual: ${challenge.currentAmount}</p>
                <p>Multiplicador: {challenge.multiplication}</p>
                <div className="status-and-button">
                  <p
                    className={`status-label ${challenge.status
                      .toLowerCase()
                      .replace(" ", "-")}`}
                  >
                    Estatus: {challenge.status}
                  </p>
                  {canViewChallenge && (
                    <button
                      className="premium-btn"
                      onClick={() => setSelectedChallenge(challenge)}
                    >
                      Ver pasos
                    </button>
                  )}
                </div>
                {!canViewChallenge && (
                  <p className="premium-offer">
                    Este reto es exclusivo para miembros premium. Por favor,
                    inicia sesión para ver los detalles.
                  </p>
                )}
                {selectedChallenge && selectedChallenge.id === challenge.id && (
                  <div className="challenge-steps">
                    <h3>Pasos</h3>
                    <div className="table-container">
                      <table>
                        <thead>
                          <tr>
                            <th>Fecha</th>
                            <th>Apuesta</th>
                            <th>Odds</th>
                            <th>Cantidad Apostada</th>
                            <th>Cantidad Ganada</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedChallenge.steps.map((step, index) => (
                            <tr key={index}>
                              <td>{step.date}</td>
                              <td>{step.bet}</td>
                              <td>{step.odds}</td>
                              <td>{step.amountBet}</td>
                              <td>{step.amountWon}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <button
                      className="premium-btn"
                      onClick={() => setSelectedChallenge(null)}
                    >
                      Cerrar pasos
                    </button>
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      {!user && (
        <div className="premium-notice">
          Algunos retos son exclusivos para miembros premium. Por favor, inicia
          sesión para ver todos los detalles.
        </div>
      )}
    </div>
  );
};

export default ChallengeViewer;
