import React, { useState, useEffect } from "react";
import { db } from "../../database/firebase";
import { doc, getDoc } from "firebase/firestore";

import "./AllTimeRecord.css";

const AllTimeRecord = () => {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("free");
  const [allTimeData, setAllTimeData] = useState(null);
  const [noDataMessage, setNoDataMessage] = useState("");

  const sportIcons = {
    Futbol: "icon-soccer",
    NBA: "icon-basketball",
    NFL: "icon-football",
    MLB: "icon-baseball",
    NHL: "icon-school",
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const getColorClass = (value) => {
    if (value < 40) {
      return "red";
    } else if (value >= 40 && value < 55) {
      return "yellow";
    } else {
      return "green";
    }
  };

  const renderSportCards = (sports) => {
    return Object.entries(sports).map(([sport, effectiveness]) => {
      if (effectiveness !== "0") {
        return (
          <div
            key={sport}
            className={`sport-card ${getColorClass(effectiveness)}`}
          >
            <i className={`${sportIcons[sport]}`} />
            <p>{sport}</p>
            <p>{effectiveness}%</p>
          </div>
        );
      } else {
        return (
          <div key={sport} className="sport-card">
            <i className={`${sportIcons[sport]}`} />
            <p>{sport}</p>
            <p>No hubo jugadas del deporte en este mes</p>
          </div>
        );
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const documentId = `${selectedMonth}-${selectedYear}`;
        const docRef = doc(db, "allTimeRecord", documentId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setAllTimeData(docSnap.data());
          setNoDataMessage("");
        } else {
          setAllTimeData(null);
          setNoDataMessage(
            `No hay registro para el grupo ${selectedGroup} en ${selectedMonth} ${selectedYear}`
          );
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    if (selectedMonth && selectedYear) {
      fetchData();
    }
  }, [selectedMonth, selectedYear, selectedGroup]);

  return (
    <div className="all-time-record-container">
      <h2>Historia Panenka</h2>
      <div className="filters">
        <select
          value={selectedMonth}
          onChange={handleMonthChange}
          className="select"
        >
          <option value="">Seleccionar Mes</option>
          <option value="Enero">Enero</option>
          <option value="Febrero">Febrero</option>
          <option value="Marzo">Marzo</option>
          <option value="Abril">Abril</option>
          <option value="Mayo">Mayo</option>
          <option value="Junio">Junio</option>
          <option value="Julio">Julio</option>
          <option value="Agosto">Agosto</option>
          <option value="Septiembre">Septiembre</option>
          <option value="Octubre">Octubre</option>
          <option value="Noviembre">Noviembre</option>
          <option value="Diciembre">Diciembre</option>
        </select>

        <select
          value={selectedYear}
          onChange={handleYearChange}
          className="select"
        >
          <option value="">Seleccionar Año</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
        </select>

        <select
          value={selectedGroup}
          onChange={handleGroupChange}
          className="select"
        >
          <option value="free">Grupo Free</option>
          <option value="premium">Grupo Premium</option>
        </select>
      </div>
      {selectedMonth && selectedYear && allTimeData ? (
        <div className="record-cards">
          {noDataMessage !== "" ? (
            <p className="noData">{noDataMessage}</p>
          ) : (
            <>
              {allTimeData[selectedGroup + "Performance"] ? (
                <>
                  {allTimeData[selectedGroup + "Performance"].global === 0 &&
                  allTimeData[selectedGroup + "Performance"].effectiveness ===
                    0 ? (
                    <p>
                      No hay registro para el grupo {selectedGroup} en{" "}
                      {selectedMonth} {selectedYear}
                    </p>
                  ) : (
                    <div
                      className={`main-card ${
                        allTimeData[selectedGroup + "Performance"].global >= 0
                          ? "positive"
                          : "negative"
                      }`}
                    >
                      <p className="main-value">
                        {allTimeData[selectedGroup + "Performance"].global >= 0
                          ? "+"
                          : "-"}
                        {Math.abs(
                          allTimeData[selectedGroup + "Performance"].global
                        )}
                        %
                      </p>
                      <p>Balance mensual</p>
                    </div>
                  )}

                  {allTimeData[selectedGroup + "Performance"].sports &&
                    Object.keys(
                      allTimeData[selectedGroup + "Performance"].sports
                    ).length > 0 &&
                    renderSportCards(
                      allTimeData[selectedGroup + "Performance"].sports
                    )}
                </>
              ) : (
                <p>
                  No hay registro para el grupo {selectedGroup} en{" "}
                  {selectedMonth} {selectedYear}
                </p>
              )}
            </>
          )}
        </div>
      ) : (
        <p className="label-alert">
          Seleccione un mes y un año disponible para ver los datos.
        </p>
      )}
    </div>
  );
};

export default AllTimeRecord;
