import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase"; // Asegúrate de que ../firebase tiene la configuración correcta de Firebase
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

export const authContext = createContext();

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("There is not auth provider");
  return context;
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialBank, setInitialBank] = useState(null);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const signup = (email, password) =>
    createUserWithEmailAndPassword(auth, email, password);

  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setCurrentUser(userCredential.user);
      return userCredential.user;
    } catch (error) {
      alert("Error en los datos de usuario");
      console.error("!! Error logging in:", error); // Añadimos un log para depuración
      throw error; // Asegúrate de lanzar el error para que pueda ser capturado en el componente
    }
  };

  const logout = () => signOut(auth);

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error("Error resetting password:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = doc(db, "users", currentUser.uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setInitialBank(userData.initialBank);
          setIsFirstTime(!userData.initialBank); // Si no hay initialBank, es la primera vez
        } else {
          setIsFirstTime(true); // Si no hay documento, es la primera vez
        }
      } else {
        setUser(null);
        setInitialBank(null);
        setIsFirstTime(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <authContext.Provider
      value={{
        signup,
        login,
        user,
        logout,
        loading,
        resetPassword,
        initialBank,
        isFirstTime,
        setIsFirstTime,
      }}
    >
      {children}
    </authContext.Provider>
  );
}
