import React, { useEffect, useState } from "react";
import { db } from "../../database/firebase";
import { collection, getDocs } from "firebase/firestore";
import "./RaffleList.css";

const RaffleList = () => {
  const [raffles, setRaffles] = useState([]);

  useEffect(() => {
    const fetchRaffles = async () => {
      try {
        const rafflesCollection = collection(db, "raffles");
        const rafflesSnapshot = await getDocs(rafflesCollection);
        const rafflesList = rafflesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRaffles(rafflesList);
      } catch (error) {
        console.error("Error fetching raffles:", error);
      }
    };

    fetchRaffles();
  }, []);

  return (
    <div className="raffle-list-container">
      <h2>Premios Panenka</h2>
      <div className="raffle-list">
        {raffles.map((raffle) => (
          <div key={raffle.id} className="raffle-card">
            <div className="raffle-image-container">
              {raffle.prizePhoto ? (
                <img
                  src={raffle.prizePhoto}
                  alt={raffle.prize}
                  className="raffle-image"
                />
              ) : (
                <div className="placeholder-image">Sin Imagen</div>
              )}
            </div>
            <div className="raffle-details">
              <h3>{raffle.prize}</h3>
              <p className="status">{raffle.status}</p>
              <p>
                <strong>Ganador: </strong>
                {raffle.winner}
              </p>
              <p>
                <strong>Fecha Límite:</strong>{" "}
                {new Date(raffle.deadline).toLocaleDateString()}
              </p>
              <p>
                <strong>Fecha de Elección del Ganador:</strong>{" "}
                {new Date(raffle.winnerSelectionDate).toLocaleDateString()}
              </p>
              <p>
                <strong>Método de Elección:</strong> {raffle.selectionMethod}
              </p>
              <p>
                <strong>Requisitos:</strong>
              </p>
              <ul>
                {raffle.requirements &&
                  raffle.requirements.map((req, index) => (
                    <li key={index}>{req}</li>
                  ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RaffleList;
