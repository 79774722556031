import React from "react";
import { AuthProvider } from "././authContext";
import AppContent from "../../AppContent";

const AuthWrapper = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default AuthWrapper;
