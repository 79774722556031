import React, { useState } from "react";
import "./Picks.css";
import GetPicks from "../../business/Picks/GetPicks";

const Picks = () => {
  const [selectedCategory, setSelectedCategory] = useState("futbol");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="picks-container">
      <div className="button-group">
        <button
          className={`pick-button ${
            selectedCategory === "Futbol" ? "selected" : ""
          }`}
          onClick={() => handleCategoryChange("Futbol")}
        >
          Futbol
        </button>
        <button
          className={`pick-button ${
            selectedCategory === "NBA" ? "selected" : ""
          }`}
          onClick={() => handleCategoryChange("NBA")}
        >
          NBA
        </button>
        <button
          className={`pick-button ${
            selectedCategory === "NHL" ? "selected" : ""
          }`}
          onClick={() => handleCategoryChange("NHL")}
        >
          NHL
        </button>
        <button
          className={`pick-button ${
            selectedCategory === "MLB" ? "selected" : ""
          }`}
          onClick={() => handleCategoryChange("MLB")}
        >
          MLB
        </button>
        <button
          className={`pick-button ${
            selectedCategory === "NFL" ? "selected" : ""
          }`}
          onClick={() => handleCategoryChange("NFL")}
        >
          NFL
        </button>
      </div>
      <div className="content">
        <GetPicks collectionName={`${selectedCategory.toLowerCase()}`} />
      </div>
    </div>
  );
};

export default Picks;
