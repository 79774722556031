import React, { useState, useEffect } from "react";
import { useAuth } from "../../database/context/authContext";
import { db } from "../../database/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth } from "../../database/firebase";
import "./Profile.css";

const Profile = () => {
  const { user } = useAuth();
  const [email, setEmail] = useState("");
  const [initialBank, setInitialBank] = useState("");
  const [newBank, setNewBank] = useState("");
  const [validity, setValidity] = useState("");
  const [seniority, setSeniority] = useState("");
  const [password, setPassword] = useState("");
  const [editBank, setEditBank] = useState(false);
  const [editPassword, setEditPassword] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDoc = doc(db, "users", user.uid);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setEmail(user.email);
          setInitialBank(userData.initialBank);
          setValidity(userData.validity);
          setSeniority(userData.seniority);
        }
      }
    };

    fetchUserData();
  }, [user]);

  const handleBankUpdate = async () => {
    if (user && newBank) {
      const userDoc = doc(db, "users", user.uid);
      await updateDoc(userDoc, { initialBank: newBank });
      setInitialBank(newBank);
      setNewBank("");
      setEditBank(false);
    }
  };

  const handlePasswordUpdate = async () => {
    if (password) {
      try {
        await auth.currentUser.updatePassword(password);
        alert("Password updated successfully");
        setPassword("");
        setEditPassword(false);
      } catch (error) {
        alert("Error updating password: " + error.message);
      }
    }
  };

  return (
    <div className="profile">
      <h2>Mi perfil</h2>
      <div className="profile-item">
        <label>Email </label>
        <span>{email}</span>
      </div>
      <div className="profile-item">
        <label>Bank base</label>

        <span>${initialBank}</span>
        <button className="edit-button" onClick={() => setEditBank(!editBank)}>
          Modificar bank base
          <i className="icon-pencil"></i>
        </button>

        {editBank && (
          <div className="edit-section">
            <input
              type="number"
              value={newBank}
              onChange={(e) => setNewBank(e.target.value)}
              placeholder="Ingresa tu bank a actualizar"
            />
            <button onClick={handleBankUpdate}>Actualizar bank</button>
          </div>
        )}
      </div>
      <div className="profile-item">
        <label>Bank actual</label>
      </div>
      <div className="profile-item">
        <button
          className="edit-button"
          onClick={() => setEditPassword(!editPassword)}
        >
          {" "}
          Actualizar contraseña
          <i className="icon-pencil"></i>
        </button>
        {editPassword && (
          <div className="edit-section">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Ingresa tu nueva contraseña"
            />
            <button onClick={handlePasswordUpdate}>
              Actualizar contraseña
            </button>
          </div>
        )}
        <div className="profile-item">
          <label>Vigencia membresía: </label>
          <span>{validity}</span>
        </div>
        <div className="profile-item">
          <label>Antigüedad: </label>
          <span>{seniority}</span>
        </div>
      </div>
    </div>
  );
};

export default Profile;
