import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../database/firebase";
import { useEffect, useState } from "react";
import { useAuth } from "../../database/context/authContext";
import PickCard from "./PickCard";
import ParlayCard from "./ParlayCard";
import "./GetPicks.css";

const GetPicks = ({ collectionName }) => {
  const [picks, setPicks] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const getPicks = async () => {
      const tempArray = [];

      // Consulta para obtener los picks
      const picksQuery = query(
        collection(db, `${collectionName}-picks`),
        orderBy("date", "desc")
      );

      // Consulta para obtener los parlays
      const parlaysQuery = query(
        collection(db, `${collectionName}-parlays-picks`),
        orderBy("date", "desc")
      );

      const picksSnapshot = await getDocs(picksQuery);
      const parlaysSnapshot = await getDocs(parlaysQuery);

      // Procesar los picks
      picksSnapshot.forEach((doc) => {
        const pickData = doc.data();
        tempArray.push({ ...pickData, id: doc.id, type: "pick" });
      });

      // Procesar los parlays
      parlaysSnapshot.forEach((doc) => {
        const parlayData = doc.data();
        tempArray.push({ ...parlayData, id: doc.id, type: "parlay" });
      });

      // Ordenar el arreglo combinado por fecha de más reciente a más antiguo
      tempArray.sort((a, b) => b.date.toDate() - a.date.toDate());

      setPicks(tempArray);
    };

    getPicks();
  }, [collectionName, user]);

  return (
    <div>
      {!user && (
        <div className="premium-offer">
          Algunos picks son exclusivos para miembros premium
        </div>
      )}
      {picks.length === 0 && ( // Verificar si no hay picks en la colección
        <div className="no-picks-message">
          No hay picks disponibles para este deporte en este momento.
        </div>
      )}
      <div className="pick-row">
        {picks.map((item, index) => {
          // Renderizar solo si el usuario está logueado o si el pick no es premium o tiene resultado
          if (user || !item.premium || item.result) {
            return item.type === "pick" ? (
              <PickCard key={index} pick={item} />
            ) : (
              <ParlayCard key={index} parlay={item} />
            );
          }
          return null; // No renderizar picks premium sin resultado para usuarios no logueados
        })}
      </div>
    </div>
  );
};

export default GetPicks;
