import AuthButton from "../../business/Button/AuthButton";
import DescriptionComponent from "./DescriptionComponent/DescriptionComponent";
import "./Home.css";

const Home = () => {
  return (
    <>
      <div className="web-app">
        <div className="short-presentation">
          <DescriptionComponent />
        </div>
      </div>
    </>
  );
};

export default Home;
