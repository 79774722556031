import React, { useState } from "react";
import { useAuth } from "../../database/context/authContext";
import "./AuthButton.css"; // Archivo CSS para estilos adicionales

const AuthButton = () => {
  const { user, logout, login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLoginForm, setShowLoginForm] = useState(false);

  const handleButtonClick = () => {
    if (user) {
      // Si el usuario está logeado, realiza la acción de logout
      logout();
    } else {
      // Si el usuario no está logeado, muestra el popup
      openPopup();
    }
  };

  const openPopup = () => {
    document.getElementById("popup").style.display = "block";
  };

  const closePopup = () => {
    document.getElementById("popup").style.display = "none";
  };

  const handleTelegramClick = () => {
    // URL de Telegram con el mensaje predefinido
    const telegramURL =
      "https://t.me/FernandoAceves?text=¡Me gustaría unirme como usuario premium!";
    // Abrir la URL en una nueva pestaña
    window.open(telegramURL, "_blank");
    // Cerrar el popup después de abrir el enlace
    closePopup();
  };

  const handleLoginWithEmail = async () => {
    try {
      await login(email, password);
      closePopup();
    } catch (error) {
      console.error("Error al iniciar sesión:", error.message);
      // Manejar el error de inicio de sesión aquí
    }
  };

  return (
    <div>
      <button onClick={handleButtonClick} className="premium-btn">
        {user ? "Salir" : "Hazte premium"}
      </button>
      <div id="popup" className="popup">
        <div className="popup-content">
          <span className="close" onClick={closePopup}>
            &times;
          </span>
          <p>
            ¡Únete a nosotros por $200 por 30 días! Envíanos un mensaje por
            Telegram para obtener más información.
            <button onClick={handleTelegramClick} className="telegram-btn">
              Contacto en Telegram para unirte
            </button>
          </p>
          {!user && (
            <div className="login-form">
              <p>Inicia sesión si ya eres miembro:</p>
              <input
                type="email"
                placeholder="Correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div>
                <button onClick={handleLoginWithEmail} className="login-btn">
                  Iniciar Sesión
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthButton;
